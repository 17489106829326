import React from 'react'
import {PageProps, Link} from 'gatsby'

import Layout from '../components/layout'
import SEO from '../components/seo'

const UsefulLinks = (props: PageProps) => (
  <Layout>
    <SEO title="Handige links" />

    <h5>Onderwijs</h5>
    <li>
      Nederlandse Onderwijsinspectie
      <p>
        <a
          href="https://www.onderwijsinspectie.nl/onderwijssectoren/nederlands-onderwijs-in-het-buitenland"
          target="_blank"
          className="usefulLinks"
          rel="noopener"
        >
          https://www.onderwijsinspectie.nl/onderwijssectoren/nederlands-onderwijs-in-het-buitenland
        </a>
      </p>
    </li>
    <li>
      Stichting Nederlands Onderwijs in het Buitenland
      <p>
        <a href="https://www.stichtingnob.nl" target="_blank" className="usefulLinks" rel="noopener">
          https://www.stichtingnob.nl
        </a>
      </p>
    </li>
    <h5>Educatieve televisie</h5>
    <p>
      <a href="https://schooltv.nl" target="_blank" rel="noopener" className="usefulLinks">
        https://schooltv.nl
      </a>
      - (o.a. Huisje, Boompje, Beestje en Nieuws uit de natuur)
    </p>
    <p>
      <a href="https://jeugdjournaal.nl" target="_blank" className="usefulLinks" rel="noopener">
        https://jeugdjournaal.nl
      </a>
    </p>
    <p>
      <a href="https://www.hetklokhuis.nl" target="_blank" className="usefulLinks" rel="noopener">
        https://www.hetklokhuis.nl
      </a>
    </p>
    <h5>Digitale boeken</h5>
    {/* <p>
      <a href="https://devoorleeshoek.nl/" target="_blank" className="usefulLinks" rel="noopener">
        https://devoorleeshoek.nl/
      </a>
    </p> */}
  </Layout>
)

export default UsefulLinks
